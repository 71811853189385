<template>
  <content-card-full>
    <template #title>
      <card-title>
        <template #title>
          <h2 class="title">RISK PROFILER</h2>
        </template>
      </card-title>
    </template>
    <template #content-full>
      <div class="flex flex-column">
        <h3 class="mb-3">Introduction</h3>
        <div class="flex align-items-center justify-content-between">
          <div class="mr-5">
            <div class="card-body">The risk profiler page provides access to the Financial Express (FE) Analytics Attitude to Risk Questionnaire, powered by EValue. Complete the questionnaire to determine the amount of risk you are willing to take with your investments.</div>
          </div>
        </div>
      </div>

      <spacer-h/>

      <div class="flex flex-column">
        <h3 class="mb-3">EVALUE ATTITUDE TO RISK QUESTIONNAIRE</h3>
        <div class="flex align-items-center justify-content-between">
          <div class="mr-5">
            <div class="card-body">Before making any investment decision, you should consider the level of risk you are prepared to accept, and the level of return you need to target, considering your aims, objectives, and the timeframe of your investment.
            </div>
            <div class="card-body">Understanding how much risk you are willing and able to take and ensuring suitability of the investment recommendations is vital in the advice process. To ensure that the method by which we assess this is as robust as possible, we regularly review the risk profiling tools available and we are now using the FE Analytics psychometric profiling tool, powered by EValue.
            </div>
            <div class="card-body">EValue’s psychometric profiling identifies characteristics such as desire for profit, emotional reaction to volatility, and investment experience. These are all general predictors of your likely attitude to risk and tolerance for loss. Whilst this is not the only means by which we assess the level of risk suitable to you, it provides a useful guide as to how we should invest our clients’ monies.
            </div>
            <div class="card-body">A PDF version of the questionnaire can be downloaded using the button below.</div>
          </div>
        </div>
      </div>

      <div class="button-cta"><a
          :href="'https://website-public-assets.clarityglobal.com/saleswebsite/pdf/EValueAttitudeToRiskQuestionnaireBLANK.pdf'"
          target="_blank">
        <Button class="clarity-btn clarity-gradient-orange">
          <Icon name="pdf-icon-orange" class="mr-3 white"/>
          EValue Risk Questionnaire
        </Button>
      </a>
      </div>

      <div class="flex flex-column justify-content-between">
        <div class="mr-5">
          <div class="card-body">This will need to be returned to our administration team via the Secure Messaging
            Portal or via email to <a class="clarity-light-blue-text" href="mailto:adminteam@clarityglobal.com">adminteam@clarityglobal.com</a>.
          </div>
          <div class="card-body">Alternatively, you can complete the questionnaire online. Click the ‘Request Risk Questionnaire’ button to request a link.
          </div>
        </div>
      </div>

      <div class="button-cta">
        <Button class="clarity-btn clarity-gradient-orange"  @click="$router.push('/securemessaging/message'); sendInternalMessage();">Request Risk Questionnaire</Button>
      </div>

      <div class="flex flex-column justify-content-between">
        <div class="mr-5">
          <div class="card-body">You will receive an email from <span class="clarity-light-blue-text">donotreply@fefundinfo.com</span> with the subject “Attitude to Risk Questionnaire from Clarity”. Clicking on the link in the email will take you to the online questionnaire.
          </div>
          <div class="card-body">
            <img src="@/assets/img/evalue-example-email.png">
          </div>
          <div class="card-body">Once we receive your questionnaire, we will suggest a ‘best fit’ target asset
            allocation based on your risk score, which can be used as a benchmark for your investments. Please see the
            research note on asset allocation for more detail.
          </div>
        </div>
      </div>

      <div class="button-cta">
        <a :href="'https://www.clarityglobal.com/sites/default/files/research/notes/asset_allocation_risk_return.pdf'"
           target="_blank">
          <Button class="clarity-btn clarity-gradient-grey">
            <Icon name="pdf-icon" class="mr-3 dark-grey"/>
            Asset Allocation Research Note
          </Button>
        </a>
      </div>

      <div class="flex flex-column align-items-center justify-content-between">
        <div class="mr-5">
          <div class="card-body">The suggested target asset allocation can be used as a benchmark and viewed against
            your existing investments, or this benchmark can be used as a guide to help you when making new investment
            decisions.
          </div>
          <div class="card-body"> We do not believe that a risk profiler should be used in isolation, nor that it can
            give a simple and quick answer to where you should invest your money. It is intended as a starting point and
            an aid for discussions. You should also consider your particular circumstances and preferences before
            arriving at a target asset allocation model for you.
          </div>
        </div>
      </div>

    </template>
  </content-card-full>
</template>

<script>
import {ref} from 'vue';
import CardTitle from '@/components/common/CardTitle';
import ContentCardFull from '@/components/common/ContentCardFull';
import Icon from '@/components/common/Icon.vue'
import SpacerH from "@/components/common/layout/SpacerH";
import {useStore} from "vuex";

export default {
  name: "TabRiskProfiler",
  setup() {
    const store = useStore()
    const isUpgraded = ref(true);
    const clarityURL = process.env.VUE_APP_CLARITYURL;


    const sendInternalMessage = () => {
      store.dispatch('globalMessageTrigger', {
        subject: 'Risk Questionnaire Request',
        category: 'Correspondence',
        recipient: 'Admin Team',
        messagebody: 'Dear Admin Team,\n\n' +
            'Please send me a link to complete the EValue Risk Questionnaire.\n\n' +
            'Many thanks'
      })
    }

    return {
      isUpgraded,
      sendInternalMessage,
      clarityURL
    }
  },
  components: {
    SpacerH,
    CardTitle,
    ContentCardFull,
    Icon
  }
}
</script>

<style scoped lang="scss">
.card-body {
  font-size: 18px;
  padding: 5px 0;
}

.button-cta {
  display: flex;
  justify-content: flex-start;

  button {
    padding: 15px 45px;
    margin: 20px 0;
    width: max-content;
  }

  img {
    opacity: 1;
  }
}
</style>
